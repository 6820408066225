<template>
  <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
    <div class="pb-5" data-wizard-type="step-content">

      <div class="d-flex justify-content-center" v-if="isReadOnly && uploadedFleetLink">
        <a :href="uploadedFleetLink" target="_blank" class="btn btn-primary float-right text-uppercase">View Uploaded Fleet Declaration</a>
      </div>

      <b-card class="mb-5 mt-3" body-class="px-7 py-5" v-if="csvboxReady && !isReadOnly">
        <div class="form-group">
          <strong>Step 1</strong> - Click the 'Download Excel Template' button below. Then open the downloaded Excel template and add your fleet details in it.<br/>

          <span v-if="currentFleetAvailable">
            Optionally, you can click on 'Download Current Fleet' button to get the details of your current fleet declared with us, and use it to fill in the Excel template.<br/>
          </span>

          <div class="row mt-5 mb-10 col-12">
            <a href="https://dkg.box.com/s/yxzo40pxvubk0ii7o076wyyqduz0jon4" target="_blank" class="btn btn-primary">Download Excel Template</a><br/>

            <button v-if="currentFleetAvailable" class="btn btn-primary ml-5" @click.prevent="getCurrentFleet">Download Current Fleet (CSV)</button><br/>
          </div>

          <strong>Step 2</strong> - Click the 'Upload Fleet' button below. Then follow the instructions on the screen and complete the process.<br/>
          <fleet-upload :branch-codes="branchCodes" :unique-id="formData.formId" v-on="$listeners"/>
        </div>
      </b-card>
  </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import FleetUpload from "@/view/pages/wizard/components/FleetUpload.vue";

export default {
  name: "VehicleUploadPage",

  props: {
    formData: Object,
    branchCodes: Array,
    csvboxReady: Boolean,
    isReadOnly: Boolean
  },

  components: { FleetUpload },

  data() {
    return {
    };
  },

  methods: {
    getCurrentFleet: function() {
      let url = `${this.$apiService.getServerUrl()}/fleet-upload/form/${this.formData.formId}/current-fleet`
      this.$apiService.get(url)
          .then(({ data }) => {
            let fleetCsv = data.result;

            const url = window.URL.createObjectURL(new Blob([fleetCsv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.formData.policyNumber}_Current_Fleet.csv`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            this.$emit('onError', error);
          });
    }
  },

  computed: {
    uploadedFleetLink: function () {
      if(this.formData) {
        return this.formData.formFleetFileLink;
      }
      return null;
    },

    currentFleetAvailable: function () {
      if(this.formData) {
        return this.formData.currentFleetAvailable;
      }
      return false;
    },
  }
};
</script>
