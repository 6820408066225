<template>
  <ValidationObserver ref="fleetUploadForm">
    <div class="card card-custom">
      <div class="card-body container">

        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
              class="wizard wizard-3"
              id="fleetUploadWizard"
              data-wizard-state="step-first"
          >
            <div class="wizard-nav border-bottom" v-show="formData">
              <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                <div v-for="(wizardPage, index) in wizardPages" :key="index" class="wizard-step" data-wizard-type="step">
                  <div class="wizard-label">
                    <h3 class="wizard-title">
                      <span>{{ index + 1 }}</span>{{ wizardPage.title }}
                    </h3>
                    <div class="wizard-bar"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                  <div class="col-xl-12 col-xxl-12">
                    <!--begin: Wizard Form-->
                    <div class="form mt-0" id="kt_form">

                      <!--begin: ERROR ALERT-->
                      <b-alert v-if="errorAlert" variant="warning" show>
                        <div class="text-dark" v-html="errorAlert"></div>
                      </b-alert>
                      <!--end: ERROR ALERT-->

                      <b-alert v-if="formData && !isFormEditable" variant="secondary" show>
                        <div class="text-dark">This form is already submitted and cannot be modified.</div>
                      </b-alert>

                      <b-card  border-variant="primary" class="mb-10 mt-3" body-class="px-7 py-5" v-if="formData">
                        <h4 class="text-center font-weight-bold">Fleet Upload Effective Period :
                          <b-badge variant="primary">
                            {{ formData.effectiveStartDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                            to
                            {{ formData.effectiveEndDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                          </b-badge>
                        </h4>
                      </b-card>

                      <!--begin: Wizard Step 1 -->
                      <rental-locations-page :form-data="formData" :is-read-only="!isFormEditable"></rental-locations-page>
                      <!--end: Wizard Step 1-->

                      <!--begin: Wizard Step 2-->
                      <vehicle-upload-page :form-data="formData" :branch-codes="branchCodes"
                                           :csvbox-ready="csvboxReady" @fleetUploadCompleted="fleetUploadCompleted" @onError="handleError"
                                           :is-read-only="!isFormEditable"
                      >
                      </vehicle-upload-page>
                      <!--end: Wizard Step 2-->

                      <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-10">
                        <div class="mr-2">
                          <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                  data-wizard-type="action-prev"
                          >
                            Previous Step
                          </button>
                        </div>
                        <div>
                          <button
                              v-if="formData && !isLastPage"
                              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                              @click.prevent="goToNextPage"
                          >
                            Next Step
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </div>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import KTWizard from "@/assets/js/components/wizard";
import _ from "lodash";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import RentalLocationsPage from "@/view/pages/wizard/fleet-upload/RentalLocationsPage";
import VehicleUploadPage from "@/view/pages/wizard/fleet-upload/VehicleUploadPage";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import stringify from "fast-json-stable-stringify";
import BrandService from "@/core/services/brand.service";

export default {
  name: "FleetUploadForm",

  components: { RentalLocationsPage, VehicleUploadPage },

  data() {
    return {
      serverUrl: null,
      errorAlert: null,
      formId: null,
      formData: null,
      formDataString: null,
      wizardObj: null,
      csvboxReady: false
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    let queryParams = this.$route.query;
    if (!queryParams.formId) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.formId = queryParams.formId;

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }
    this.$apiService.setServerUrl(this.serverUrl);

    // Get form data.
    this.getFormDataAndInitialiseUI();
  },

  computed: {

    wizardPages: function() {
      return [
        { pageNumber: 1, title: "Confirm Rental Locations", id: 'wizardPage1'},
        { pageNumber: 2, title: "Upload Your Fleet", id: 'wizardPage2'}
      ];
    },

    branchCodes: function() {
      let branchCodes = [];
      if(this.formData && Array.isArray(this.formData.clientBranches)) {
        branchCodes = _.map(this.formData.clientBranches, 'K_BranchCode');
      }
      for (let [i, branchCode] of branchCodes.entries()) {
        if(branchCode) {
          branchCodes[i] = branchCode.toUpperCase();
        }
      }
      return branchCodes;
    },

    isLastPage: function() {
      return this.wizardObj && (this.wizardObj.getStep() === this.wizardPages.length);
    },

    isFormEditable: function () {
      return this.formData && (this.formData.formStatus === 'Initialised' || this.formData.formStatus === 'Incomplete');
    }
  },

  methods: {

    initWizard: function () {
      // Initialize form wizard
      const wizard = new KTWizard("fleetUploadWizard", {
        startStep: 1, // Initial active step number
        clickableSteps: false, // Do not allow step clicking
      });

      this.wizardObj = wizard;

      // Before-Change event - Save app-data to server
      wizard.on("beforeChange", (wizardObj, newPage) => {
        this.clearError();

        if(newPage === 1) {
          this.csvboxReady = false;
        }
        if(newPage === 2) {
          if (this.formData) {
            // Save app-data to server
            this.saveFormData();
          }

          this.$nextTick(()=> {
            this.csvboxReady = true;
          });
        }
      });

      // Change event - Scroll to top of the page
      wizard.on("change", (/*wizardObj*/) => {
        setTimeout(function () {
          KTUtil.scrollTop();
        }, 500);
      });
    },

    getFormDataAndInitialiseUI: function () {
      this.$apiService.get(`${this.serverUrl}/fleet-upload/form/${this.formId}`)
          .then(({ data }) => {
            this.formData = data.result;
            this.formDataString = stringify(this.formData);

            // Set UI Brand
            BrandService.setUIBrand(this.formData.brand);

            // Set wizard title and subtitle
            this.$store.commit("setWizardTitle", `Fleet Upload : ${this.formData.policyNumber}`);
            this.$store.commit("setWizardSubtitle", this.formData.clientName);

            this.$nextTick(() => {
              // Initialise the UI wizard
              this.initWizard();

              setTimeout(() => {
                this.stopPageLoading();
              }, 0);
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    goToNextPage: async function() {
      this.clearError();
      let isNavAllowed = await this.isPageValid(this.wizardObj.getStep());

      if(isNavAllowed){
        this.wizardObj.goNext(true);
      }
      else {
        this.setError('Please complete all fields on this page.');
      }
    },

    saveFormData: function () {
      if (!this.isFormEditable) {
        return;
      }

      // Save form-data to the server - only if it has changed
      let newFormDataString = stringify(this.formData);
      if (newFormDataString !== this.formDataString) {
        // Show page loading
        this.startPageLoading();

        this.$apiService.post(`${this.serverUrl}/fleet-upload/form/${this.formId}`, this.formData)
          .then(() => {
            this.formDataString = newFormDataString;

            // Stop page loading
            this.stopPageLoading();
          })
          .catch((error) => {
            this.handleError(error);
            this.csvboxReady = false;
          });
      }
    },

    isPageValid: async function(pageNumber){
      let isValid = false;
      if(!this.$refs || !this.$refs.fleetUploadForm || !this.formData){
        return isValid;
      }

      let page = this.wizardPages.find(wp => {
        return wp.pageNumber === pageNumber;
      });
      if(!page) {
        console.log(`Invalid page number ${pageNumber}`);
        return isValid;
      }

      let wizardPageId = page.id;
      let isDevelopment = process.env.NODE_ENV === 'development';

      let formChildren = this.$refs.fleetUploadForm.$children;
      if(Array.isArray(formChildren)) {
        let currentPageComponent = _.find(formChildren, child => {
          return child.$el && child.$el.id === wizardPageId;
        });
        if(!currentPageComponent) {
          if(isDevelopment) {
            console.log(`Could not find currentPageComponent for id ${wizardPageId}`);
          }
        }
        else {
          let currentPageRef = currentPageComponent.$refs[wizardPageId];
          if(!currentPageRef) {
            if(isDevelopment) {
              console.log(`Could not find currentPageRef for id ${wizardPageId}`);
            }
          }
          else {
            isValid = await currentPageRef.validate();
            if(!isValid && isDevelopment) {
              console.log(currentPageRef.errors);
            }
          }
        }
      }

      return isValid;
    },

    fleetUploadCompleted: function (result, data) {
      if (result && data) {
        this.formData.formFleetImportId = data.import_id;

        this.startPageLoading();
        this.$apiService.post(`${this.serverUrl}/fleet-upload/form/${this.formId}/submit`, this.formData)
            .then(() => {
              this.stopPageLoading();
              this.formData.formStatus = 'Active';

              Swal.fire({
                titleText: "Fleet Upload Successful",
                html: `<p>Thank you for uploading your fleet details.</p>
                         <br/><span class="font-weight-bold">Please close this browser window.</span>`,
                icon: "success",
                width: "40rem",
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false
              });
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
      else {
        this.setError("Fleet Upload failed! Please try again.");
      }
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
