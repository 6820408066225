<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
      <div class="mb-10" v-if="formData">

        <div class="font-weight-bold font-size-lg mb-10 mt-0">
          Please review your rental locations below. Click on 'Add Rental Location' button to add a new location.
        </div>

        <table>
        <thead>
          <th style="width: 30%"></th>
        </thead>
        <tr v-for="(rentalLocation, index) in formData.clientBranches" :key="index">
          <td>
            <b-card class="mb-4" bg-variant="light" body-class="p-4" border-variant="dark">
              <div class="row">
                <div class="col-12">
                  <label class="text-left font-size-h5"><strong>Rental Location {{ index + 1 }} - {{ rentalLocation.K_BranchCode || ''}}</strong></label>
                  <button v-if="!isReadOnly" class="btn btn-danger m-2 float-right" @click.prevent="confirmBranchDeletion(index)">
                    Delete
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <rental-location :location-entry="rentalLocation" :allow-head-office-selection="allowHeadOfficeSelection"
                                   :is-read-only="isReadOnly">
                  </rental-location>
                </div>
              </div>
            </b-card>
          </td>
        </tr>
      </table>
        <button v-if="!isReadOnly" class="btn btn-primary m-2" @click.prevent="addRentalLocationEntry()">
          Add Rental Location
        </button>
        <ValidationProvider v-if="!isReadOnly" rules="min_count:1" name="numberOfRentalLocations" v-slot="{ errors }">
          <input type="number" v-model="formData.clientBranches.length" hidden/>
          <span class="text-danger"><br>{{ errors[0] }}</span>
        </ValidationProvider>

        <b-modal ref="deleteBranchConfirmationModal" title="Delete Rental Location" size="md" no-close-on-backdrop hide-header-close @ok="removeRentalLocationEntry">
          <div class="form-group" v-if="branchToBeDeleted">
            Are you sure you want to delete this Rental Location?<br/><br/>
            <strong>{{ branchToBeDeleted.K_BranchCode }} - {{ getAddress(branchToBeDeleted) }}</strong>
          </div>
        </b-modal>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import RentalLocation from "@/view/pages/wizard/fleet-upload/RentalLocation";

export default {
  name: "RentalLocationsPage",

  props: {
    formData: Object,
    isReadOnly: Boolean
  },

  components: { RentalLocation },

  data() {
    return {
      branchToBeDeletedIndex: null
    };
  },

  methods: {
    addRentalLocationEntry: function () {
      this.formData.clientBranches.push({ addressDetails: {} });
    },

    confirmBranchDeletion: function (index) {
      this.branchToBeDeletedIndex = index;
      this.$refs["deleteBranchConfirmationModal"].show();
    },

    removeRentalLocationEntry: function () {
      if(this.branchToBeDeletedIndex >= 0) {
        this.formData.clientBranches.splice(this.branchToBeDeletedIndex, 1);
      }
      this.branchToBeDeletedIndex = null;
    },

    getAddress: function(branch) {
      let addr = branch.addressDetails;
      if(addr) {
        return [addr.streetAddress, addr.suburb, addr.postcode, addr.state].join(' ');
      }
    }
  },

  computed: {
    allowHeadOfficeSelection: function () {
      if(this.formData && Array.isArray(this.formData.clientBranches)) {
        return !_.some(this.formData.clientBranches, { 'K_Category': 'Head Office'});
      }
      return true;
    },

    branchToBeDeleted: function () {
      if(this.branchToBeDeletedIndex >= 0) {
        return this.formData.clientBranches[this.branchToBeDeletedIndex];
      }
      return null;
    }
  }
};
</script>
